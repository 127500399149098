// eslint-disable-next-line no-restricted-imports
import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useChadFinanceVaultContract } from 'hooks/useContract'
import { ReactNode, useMemo } from 'react'
import { calculateGasMargin } from 'utils/calculateGasMargin'

export enum RepayCallbackState {
  INVALID,
  LOADING,
  VALID,
}

interface UseRepayCallbackReturns {
  state: RepayCallbackState
  callback?: () => Promise<TransactionResponse>
  error?: ReactNode
}

interface UseRepayCallbackArgs {
  repayAmount: BigNumber | undefined
  tokenId: BigNumber | undefined
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useRepay({ repayAmount, tokenId }: UseRepayCallbackArgs): UseRepayCallbackReturns {
  const { account, chainId, library } = useActiveWeb3React()

  const vault = useChadFinanceVaultContract()

  return useMemo(() => {
    if (!repayAmount || !library || !account || !chainId || !vault || !tokenId) {
      return { state: RepayCallbackState.INVALID, error: <Trans>Missing dependencies</Trans> }
    }

    return {
      state: RepayCallbackState.VALID,
      callback: async () => {
        const estimatedGas = await vault.estimateGas.repay(tokenId, repayAmount).catch(() => {
          // general fallback for tokens which restrict approval amounts
          throw Error('Invalid borrow amount')
        })

        return vault
          .repay(tokenId, repayAmount, {
            gasLimit: calculateGasMargin(estimatedGas),
          })
          .catch((error: Error) => {
            throw error
          })
      },
    }
  }, [library, account, chainId, repayAmount, tokenId, vault])
}
