import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import BorrowInputPanel from 'components/BorrowInputPanel'
import { LoadingRows } from 'components/Loader/styled'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import { MouseoverTooltip } from 'components/Tooltip'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useBorrowCallback } from 'hooks/useBorrowCallback'
import { useChadFinancePositionFromTokenId } from 'hooks/useChadFinancePositions'
import { useChadFinanceVaultContract } from 'hooks/useContract'
import JSBI from 'jsbi'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { CheckCircle, HelpCircle } from 'react-feather'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'

import BorrowHeader from '../../components/Borrow/BorrowHeader'
import { BorrowCallbackError, Wrapper } from '../../components/Borrow/styleds'
import { ButtonConfirmed, ButtonError, ButtonLight } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import Loader from '../../components/Loader'
import { AutoRow } from '../../components/Row'
import { UUSD } from '../../constants/tokens'
import { ApprovalState, useUniNFTApprovalForTokenIdCallback } from '../../hooks/useApproveCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useBorrowActionHandlers, useDerivedBorrowInfo } from '../../state/borrow/hooks'
import { useExpertModeManager } from '../../state/user/hooks'
import AppBody from '../AppBody'

const AlertWrapper = styled.div`
  max-width: 460px;
  width: 100%;
`
const HoverText = styled(ThemedText.Main)`
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  :hover {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
  }
`

export default function Borrow({
  match: {
    params: { tokenId: tokenIdFromUrl },
  },
  history,
}: RouteComponentProps<{ tokenId?: string }>) {
  const { account, chainId } = useActiveWeb3React()

  const parsedTokenId = tokenIdFromUrl ? BigNumber.from(tokenIdFromUrl) : undefined

  const position = useChadFinancePositionFromTokenId(parsedTokenId)

  const maxBorrowBalance = useMemo(() => {
    if (position.loading) {
      return CurrencyAmount.fromRawAmount(UUSD[chainId ? chainId : 1], '0')
    }

    if (!position.loading && position.position && position.position.debt.lte(position.position.maxDebt)) {
      const borrowValue = position.position.maxDebt
        .sub(position.position.debt)
        .mul(BigNumber.from(98))
        .div(BigNumber.from(100))

      return CurrencyAmount.fromRawAmount(UUSD[chainId ? chainId : 1], borrowValue.toString())
    }

    return CurrencyAmount.fromRawAmount(UUSD[chainId ? chainId : 1], '0')
  }, [position])

  const chadFinanceVault = useChadFinanceVaultContract()

  const currency = UUSD[chainId ? chainId : 1]
  const { parsedAmount, inputError: borrowInputError } = useDerivedBorrowInfo()

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  const { onUserInput } = useBorrowActionHandlers()
  const isValid =
    parsedAmount?.greaterThan(JSBI.BigInt(0)) &&
    (parsedAmount.lessThan(maxBorrowBalance) || parsedAmount.equalTo(maxBorrowBalance)) &&
    maxBorrowBalance.greaterThan(JSBI.BigInt(0))

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(value)
    },
    [onUserInput]
  )

  const [{ showConfirm, borrowToConfirm, borrowErrorMessage, attemptingTxn, txHash }, setBorrowState] = useState<{
    showConfirm: boolean
    borrowToConfirm: CurrencyAmount<Currency> | undefined
    attemptingTxn: boolean
    borrowErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    borrowToConfirm: CurrencyAmount.fromRawAmount(UUSD[chainId ? chainId : 1], JSBI.BigInt(0)),
    attemptingTxn: false,
    borrowErrorMessage: undefined,
    txHash: undefined,
  })

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useUniNFTApprovalForTokenIdCallback(
    parsedTokenId ? parsedTokenId : BigNumber.from(0),
    chadFinanceVault?.address
  )

  const handleApprove = useCallback(async () => {
    await approveCallback()
  }, [approveCallback])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalState, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(() => maxBorrowBalance, [maxBorrowBalance])

  const showMaxButton = Boolean(maxBorrowBalance?.greaterThan(0) && !parsedAmount?.equalTo(maxBorrowBalance))

  const { state: borrowState, callback: borrowCallback } = useBorrowCallback(parsedAmount, parsedTokenId)

  const handleBorrow = useCallback(async () => {
    if (!borrowCallback || !parsedAmount?.greaterThan(JSBI.BigInt(0))) {
      return
    }

    setBorrowState({
      attemptingTxn: true,
      borrowToConfirm: parsedAmount,
      showConfirm,
      borrowErrorMessage: undefined,
      txHash: undefined,
    })

    borrowCallback()
      .then((hash) => {
        setBorrowState({
          attemptingTxn: false,
          borrowToConfirm: parsedAmount,
          showConfirm,
          borrowErrorMessage: undefined,
          txHash: hash,
        })
      })
      .catch((error) => {
        setBorrowState({
          attemptingTxn: false,
          borrowToConfirm: parsedAmount,
          showConfirm,
          borrowErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [borrowCallback, parsedAmount, showConfirm, account, parsedTokenId])
  const showApproveFlow =
    approvalState === ApprovalState.NOT_APPROVED ||
    approvalState === ApprovalState.PENDING ||
    (approvalSubmitted && approvalState === ApprovalState.APPROVED)

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(maxBorrowBalance.toExact())
  }, [maxInputAmount, onUserInput])

  return (
    <>
      {parsedTokenId && (
        <>
          <Link
            style={{
              justifySelf: 'start',
              textDecoration: 'none',
              marginBottom: '0.5rem',
              width: '100%',
              maxWidth: '480px',
            }}
            to={`/borrow/position/${tokenIdFromUrl}`}
          >
            <HoverText>
              <Trans>← Back to Pool</Trans>
            </HoverText>
          </Link>
          {/* <ContractWarningBanner maxWidth={'480px'} /> */}
          <AppBody>
            <BorrowHeader />
            <Wrapper id="borrow-page">
              {Boolean(position?.loading) ? (
                <LoadingRows style={{ gap: '8px' }}>
                  <div />
                  <div />
                  <div />
                </LoadingRows>
              ) : (
                <AutoColumn gap={'sm'}>
                  <div style={{ display: 'relative' }}>
                    <BorrowInputPanel
                      tokenId={parsedTokenId}
                      label={<Trans>Borrow</Trans>}
                      value={parsedAmount?.toExact() || ''}
                      showMaxButton={showMaxButton}
                      currency={currency}
                      onUserInput={handleTypeInput}
                      onMax={handleMaxInput}
                      showCommonBases={true}
                      id="borrow-input"
                      loading={position.loading}
                    />
                  </div>

                  <div>
                    {!account ? (
                      <ButtonLight onClick={toggleWalletModal}>
                        <Trans>Connect Wallet</Trans>
                      </ButtonLight>
                    ) : showApproveFlow ? (
                      <AutoRow style={{ flexWrap: 'nowrap', width: '100%' }}>
                        <AutoColumn style={{ width: '100%' }} gap="12px">
                          <ButtonConfirmed
                            onClick={handleApprove}
                            disabled={approvalState !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                            width="100%"
                            altDisabledStyle={approvalState === ApprovalState.PENDING} // show solid button while waiting
                            confirmed={approvalState === ApprovalState.APPROVED}
                          >
                            <AutoRow justify="space-between" style={{ flexWrap: 'nowrap' }}>
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                {/* we need to shorten this string on mobile */}
                                {approvalState === ApprovalState.APPROVED ? (
                                  <Trans>You can now use your Uniswap V3 NFT position to borrow CUSD</Trans>
                                ) : (
                                  <Trans>Allow the Chad Finance Protocol to use your Uniswap V3 NFT position</Trans>
                                )}
                              </span>
                              {approvalState === ApprovalState.PENDING ? (
                                <Loader stroke="white" />
                              ) : approvalSubmitted && approvalState === ApprovalState.APPROVED ? (
                                <CheckCircle size="20" color={theme.green1} />
                              ) : (
                                <MouseoverTooltip
                                  text={
                                    <Trans>
                                      You must give the Chad Finance smart contracts permission to use your Uniswap V3
                                      NFT position. You only have to do this once per token.
                                    </Trans>
                                  }
                                >
                                  <HelpCircle size="20" color={'white'} style={{ marginLeft: '8px' }} />
                                </MouseoverTooltip>
                              )}
                            </AutoRow>
                          </ButtonConfirmed>
                          <ButtonError
                            onClick={handleBorrow}
                            width="100%"
                            id="borrow-button"
                            disabled={!isValid || position.loading || approvalState !== ApprovalState.APPROVED}
                            error={isValid}
                          >
                            <Text fontSize={16} fontWeight={500}>
                              <Trans>Borrow</Trans>
                            </Text>
                          </ButtonError>
                        </AutoColumn>
                      </AutoRow>
                    ) : (
                      <ButtonError
                        onClick={handleBorrow}
                        id="borrow-button"
                        disabled={!isValid || position.loading}
                        error={isValid}
                      >
                        <Text fontSize={20} fontWeight={500}>
                          {borrowInputError ? (
                            borrowInputError
                          ) : position.loading ? (
                            <Trans>Loading position</Trans>
                          ) : (
                            <Trans>Borrow</Trans>
                          )}
                        </Text>
                      </ButtonError>
                    )}
                    {isExpertMode && borrowErrorMessage ? <BorrowCallbackError error={borrowErrorMessage} /> : null}
                  </div>
                </AutoColumn>
              )}
            </Wrapper>
          </AppBody>
        </>
      )}
      <AlertWrapper>
        <NetworkAlert />
      </AlertWrapper>
    </>
  )
}
