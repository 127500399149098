import { Currency, Ether, NativeCurrency, Token, WETH9 } from '@uniswap/sdk-core'
import invariant from 'tiny-invariant'

import { UNI_ADDRESS } from './addresses'
import { SupportedChainId } from './chains'

export const USDC_MAINNET = new Token(
  SupportedChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD//C'
)
export const USDC_E_OP = new Token(
  SupportedChainId.OPTIMISM,
  '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
  6,
  'USDC',
  'USD//C'
)
export const USDC_BASE = new Token(
  SupportedChainId.BASE,
  '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
  6,
  'USDC',
  'USDC'
)
export const USDC_SCROLL = new Token(
  SupportedChainId.SCROLL,
  '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4',
  6,
  'USDC',
  'USDC'
)
export const USDC_SCROLL_TESTNET = new Token(
  SupportedChainId.SCROLL_TESTNET,
  '0x690000EF01deCE82d837B5fAa2719AE47b156697',
  18,
  'USDC',
  'USDC'
)

export const USDBC_BASE = new Token(
  SupportedChainId.BASE,
  '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
  6,
  'USDbC',
  'USD Base Coin'
)
export const USDC_ROPSTEN = new Token(
  SupportedChainId.ROPSTEN,
  '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
  6,
  'USDC',
  'USD//C'
)
export const USDC_RINKEBY = new Token(
  SupportedChainId.RINKEBY,
  '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
  6,
  'tUSDC',
  'test USD//C'
)
export const USDC_GOERLI = new Token(
  SupportedChainId.GOERLI,
  '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
  6,
  'USDC',
  'USD//C'
)
export const USDC_KOVAN = new Token(
  SupportedChainId.KOVAN,
  '0x3861e9F29fcAFF738906c7a3a495583eE7Ca4C18',
  6,
  'USDC',
  'USD//C'
)
export const USDC_OPTIMISM = new Token(
  SupportedChainId.OPTIMISM,
  '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
  6,
  'USDC',
  'USDC'
)
export const SUSD_OPTIMISM = new Token(
  SupportedChainId.OPTIMISM,
  '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
  6,
  'SUSD',
  'SUSD'
)
export const AMPL = new Token(
  SupportedChainId.MAINNET,
  '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
  9,
  'AMPL',
  'Ampleforth'
)
export const DAI = new Token(
  SupportedChainId.MAINNET,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const DAI_BASE = new Token(
  SupportedChainId.BASE,
  '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const DAI_OPTIMISM = new Token(
  SupportedChainId.OPTIMISM,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai stable coin'
)

export const CUSD_MAINNET = new Token(
  SupportedChainId.MAINNET,
  '0x0b6A24A288eF6e1bFFd94EBe0EE026D80Cb4cE82',
  18,
  'CUSD',
  'Chad USD'
)
export const CUSD_BASE = new Token(
  SupportedChainId.BASE,
  '0x4493faE71502871245B7049580b3195bC930e661',
  18,
  'CUSD',
  'Chad USD'
)
export const CUSD_OPTIMISM = new Token(
  SupportedChainId.OPTIMISM,
  '0x3Df6a89859089715235153582f53114898bB0145',
  18,
  'CUSD',
  'Chad USD'
)
export const CUSD_SCROLL = new Token(
  SupportedChainId.SCROLL,
  '0x3cfe56cacf4042057645da9472f6cd51fcb05684',
  18,
  'CUSD',
  'Chad USD'
)
export const UUSD: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: CUSD_MAINNET,
  // [SupportedChainId.POLYGON]: CUSD_POLYGON,
  [SupportedChainId.BASE]: CUSD_BASE,
  [SupportedChainId.OPTIMISM]: CUSD_OPTIMISM,
  [SupportedChainId.SCROLL]: CUSD_SCROLL,
}

export const USDC: { [chainId in SupportedChainId]: Token } = {
  [SupportedChainId.MAINNET]: USDC_MAINNET,
  [SupportedChainId.OPTIMISM]: USDC_OPTIMISM,
  [SupportedChainId.GOERLI]: USDC_GOERLI,
  [SupportedChainId.RINKEBY]: USDC_RINKEBY,
  [SupportedChainId.KOVAN]: USDC_KOVAN,
  [SupportedChainId.ROPSTEN]: USDC_ROPSTEN,
  [SupportedChainId.BASE]: USDC_BASE,
  [SupportedChainId.SCROLL]: USDC_SCROLL,
  [SupportedChainId.SCROLL_TESTNET]: USDC_SCROLL_TESTNET,
}
export const USDT = new Token(
  SupportedChainId.MAINNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD'
)
export const USDT_SCROLL = new Token(
  SupportedChainId.SCROLL,
  '0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df',
  6,
  'USDT',
  'Tether USD'
)
export const USDT_OPTIMISM = new Token(
  SupportedChainId.OPTIMISM,
  '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
  6,
  'USDT',
  'Tether USD'
)
export const WBTC_OPTIMISM = new Token(
  SupportedChainId.OPTIMISM,
  '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
  8,
  'WBTC',
  'Wrapped BTC'
)
export const WBTC = new Token(
  SupportedChainId.MAINNET,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC'
)
export const FEI = new Token(
  SupportedChainId.MAINNET,
  '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
  18,
  'FEI',
  'Fei USD'
)
export const TRIBE = new Token(
  SupportedChainId.MAINNET,
  '0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B',
  18,
  'TRIBE',
  'Tribe'
)
export const FRAX = new Token(
  SupportedChainId.MAINNET,
  '0x853d955aCEf822Db058eb8505911ED77F175b99e',
  18,
  'FRAX',
  'Frax'
)
export const FXS = new Token(
  SupportedChainId.MAINNET,
  '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
  18,
  'FXS',
  'Frax Share'
)
export const renBTC = new Token(
  SupportedChainId.MAINNET,
  '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D',
  8,
  'renBTC',
  'renBTC'
)
export const ETH2X_FLI = new Token(
  SupportedChainId.MAINNET,
  '0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD',
  18,
  'ETH2x-FLI',
  'ETH 2x Flexible Leverage Index'
)
export const sETH2 = new Token(
  SupportedChainId.MAINNET,
  '0xFe2e637202056d30016725477c5da089Ab0A043A',
  18,
  'sETH2',
  'StakeWise Staked ETH2'
)
export const rETH2 = new Token(
  SupportedChainId.MAINNET,
  '0x20BC832ca081b91433ff6c17f85701B6e92486c5',
  18,
  'rETH2',
  'StakeWise Reward ETH2'
)
export const SWISE = new Token(
  SupportedChainId.MAINNET,
  '0x48C3399719B582dD63eB5AADf12A40B4C3f52FA2',
  18,
  'SWISE',
  'StakeWise'
)
export const WETH_MAINNET = new Token(
  SupportedChainId.MAINNET,
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  18,
  'WETH',
  'Wrapped Ether'
)
export const WETH_BASE = new Token(
  SupportedChainId.BASE,
  '0x4200000000000000000000000000000000000006',
  18,
  'WETH',
  'Wrapped Ether'
)
export const WETH_OP = new Token(
  SupportedChainId.OPTIMISM,
  '0x4200000000000000000000000000000000000006',
  18,
  'WETH',
  'Wrapped Ether'
)
export const WETH_SCROLL_TESTNET = new Token(
  SupportedChainId.SCROLL_TESTNET,
  '0x5300000000000000000000000000000000000004',
  18,
  'WETH',
  'Wrapped Ether'
)
export const WETH_SCROLL = new Token(
  SupportedChainId.SCROLL,
  '0x5300000000000000000000000000000000000004',
  18,
  'WETH',
  'Wrapped Ether'
)
export const WSteth_MAINNET = new Token(
  SupportedChainId.MAINNET,
  '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
  18,
  'WstETH',
  'Wrapped liquid staked Ether'
)
export const WSteth_BASE = new Token(
  SupportedChainId.BASE,
  '0xc1CBa3fCea344f92D9239c08C0568f6F2F0ee452',
  18,
  'WstETH',
  'Wrapped liquid staked Ether'
)
export const WSteth_OP = new Token(
  SupportedChainId.BASE,
  '0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb',
  18,
  'WstETH',
  'Wrapped liquid staked Ether'
)
export const WSteth_SCROLL = new Token(
  SupportedChainId.SCROLL,
  '0xf610A9dfB7C89644979b4A0f27063E9e7d7Cda32',
  18,
  'WstETH',
  'Wrapped liquid staked Ether'
)
export const UNI: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(SupportedChainId.MAINNET, UNI_ADDRESS[1], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.RINKEBY]: new Token(SupportedChainId.RINKEBY, UNI_ADDRESS[4], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.ROPSTEN]: new Token(SupportedChainId.ROPSTEN, UNI_ADDRESS[3], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.GOERLI]: new Token(SupportedChainId.GOERLI, UNI_ADDRESS[5], 18, 'UNI', 'Uniswap'),
  [SupportedChainId.KOVAN]: new Token(SupportedChainId.KOVAN, UNI_ADDRESS[42], 18, 'UNI', 'Uniswap'),
  // [SupportedChainId.POLYGON]: new Token(SupportedChainId.POLYGON, UNI_ADDRESS[137], 18, 'UNI', 'Uniswap'),
}

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token | undefined } = {
  ...(WETH9 as Record<SupportedChainId, Token>),
  [SupportedChainId.BASE]: new Token(8453, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether'),
  [SupportedChainId.OPTIMISM]: new Token(
    SupportedChainId.OPTIMISM,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.SCROLL_TESTNET]: WETH_SCROLL_TESTNET,
  [SupportedChainId.SCROLL]: WETH_SCROLL,
}

function isMatic(chainId: number) {
  return false
}

class MaticNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isMatic(this.chainId)) throw new Error('Not matic')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(chainId: number) {
    if (!isMatic(chainId)) throw new Error('Not matic')
    super(chainId, 18, 'MATIC', 'Polygon Matic')
  }
}

export class ExtendedEther extends Ether {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    if (wrapped) return wrapped
    throw new Error('Unsupported chain ID')
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } = {}

  public static onChain(chainId: number): ExtendedEther {
    return this._cachedExtendedEther[chainId] ?? (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency } = {}
export function nativeOnChain(chainId: number): NativeCurrency {
  return (
    cachedNativeCurrency[chainId] ??
    (cachedNativeCurrency[chainId] = isMatic(chainId)
      ? new MaticNativeCurrency(chainId)
      : ExtendedEther.onChain(chainId))
  )
}

export const TOKEN_SHORTHANDS: { [shorthand: string]: { [chainId in SupportedChainId]?: string } } = {
  USDC: {
    [SupportedChainId.MAINNET]: USDC_MAINNET.address,
    [SupportedChainId.GOERLI]: USDC_GOERLI.address,
    [SupportedChainId.RINKEBY]: USDC_RINKEBY.address,
    [SupportedChainId.KOVAN]: USDC_KOVAN.address,
    [SupportedChainId.ROPSTEN]: USDC_ROPSTEN.address,
    [SupportedChainId.OPTIMISM]: USDC_OPTIMISM.address,
  },
}
