import { TradeType } from '@uniswap/sdk-core'

interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

/**
 * Be careful adding to this enum, always assign a unique value (typescript will not prevent duplicate values).
 * These values is persisted in state and if you change the value it will cause errors
 */
export enum TransactionType {
  APPROVAL = 0,
  BORROW = 1,
  REPAY = 6,
  WRAP = 7,
  ADD_LIQUIDITY_V3_POOL = 9,
  COLLECT_FEES = 12,
  REMOVE_LIQUIDITY_V3 = 13,
  SUBMIT_PROPOSAL = 14,
  SWAP = 15,
  DELEGATE = 16,
  CLAIM_FEES = 17,
  WITHDRAW = 18,
}

export interface BaseTransactionInfo {
  type: TransactionType
}

export interface BorrowTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.BORROW
  tokenId: number
  borrowAmount: string
}
export interface CollectFeesTransactionInfo {
  type: TransactionType.COLLECT_FEES
  currencyId0: string
  currencyId1: string
}

export interface RepayTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.REPAY
  tokenId: number
  repayAmount: string
}

export interface DelegateTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.DELEGATE
  delegatee: string
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.APPROVAL
  tokenAddress: string
  spender: string
}

interface BaseSwapTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.SWAP
  tradeType: TradeType
  inputCurrencyId: string
  outputCurrencyId: string
}

export interface ExactInputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_INPUT
  inputCurrencyAmountRaw: string
  expectedOutputCurrencyAmountRaw: string
  minimumOutputCurrencyAmountRaw: string
}
export interface ExactOutputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_OUTPUT
  outputCurrencyAmountRaw: string
  expectedInputCurrencyAmountRaw: string
  maximumInputCurrencyAmountRaw: string
}
export interface WrapTransactionInfo {
  type: TransactionType.WRAP
  unwrapped: boolean
  currencyAmountRaw: string
  chainId?: number
}
export interface ClaimFeesTransactionInfo {
  type: TransactionType.CLAIM_FEES
  tokenId: string
}
export interface AddLiquidityV3PoolTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY_V3_POOL
  createPool: boolean
  baseCurrencyId: string
  quoteCurrencyId: string
  feeAmount: number
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}
export interface WithdrawTransactionInfo {
  type: TransactionType.WITHDRAW
  tokenId: string
}

export interface RemoveLiquidityV3TransactionInfo {
  type: TransactionType.REMOVE_LIQUIDITY_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

export interface SubmitProposalTransactionInfo {
  type: TransactionType.SUBMIT_PROPOSAL
}

export type TransactionInfo =
  | ApproveTransactionInfo
  | BorrowTransactionInfo
  | RepayTransactionInfo
  // | ExactOutputSwapTransactionInfo
  // | ExactInputSwapTransactionInfo
  // | ClaimTransactionInfo
  // | VoteTransactionInfo
  | DelegateTransactionInfo
  // | DepositLiquidityStakingTransactionInfo
  // | WithdrawLiquidityStakingTransactionInfo
  | WrapTransactionInfo
  // | CreateV3PoolTransactionInfo
  | AddLiquidityV3PoolTransactionInfo
  // | AddLiquidityV2PoolTransactionInfo
  // | MigrateV2LiquidityToV3TransactionInfo
  | CollectFeesTransactionInfo
  | RemoveLiquidityV3TransactionInfo
  // | SubmitProposalTransactionInfo
  | ClaimFeesTransactionInfo
  | WithdrawTransactionInfo

export interface TransactionDetails {
  hash: string
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  from: string
  info: TransactionInfo
}
