import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import { useCallback, useMemo } from 'react'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import { useSingleCallResult } from './multicall'
import { ApprovalState } from './useApproval'

export function useUniNFTTokenAllowance(owner?: string, spender?: string): boolean | undefined {
  const uniNFT = useV3NFTPositionManagerContract()

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(uniNFT, 'isApprovedForAll', inputs).result

  return useMemo(() => (allowance ? (allowance.toString() == 'true' ? true : false) : undefined), [allowance])
}
export function useUniNFTTokenIdAllowance(tokenId: BigNumber, spender?: string): boolean | undefined {
  const uniNFT = useV3NFTPositionManagerContract()

  const inputs = useMemo(() => [tokenId], [tokenId])
  const owner = useSingleCallResult(uniNFT, 'ownerOf', inputs).result

  const allowance = useSingleCallResult(uniNFT, 'getApproved', inputs).result

  return useMemo(() => {
    if (owner?.toString() == String(spender)) {
      return true
    } else {
      return allowance ? (allowance.toString() == String(spender) ? true : false) : undefined
    }
  }, [allowance, owner])
}

export function useUniNFTApprovalStateForSpender(
  spender: string | undefined,
  useIsPendingApproval: (spender?: string) => boolean
): ApprovalState {
  const { account } = useActiveWeb3React()
  const isAllowed = useUniNFTTokenAllowance(account ?? undefined, spender)
  const pendingApproval = useIsPendingApproval(spender)

  return useMemo(() => {
    if (!spender) return ApprovalState.UNKNOWN
    // we might not have enough data to know whether or not we need to approve
    if (isAllowed == undefined) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return !isAllowed ? (pendingApproval ? ApprovalState.PENDING : ApprovalState.NOT_APPROVED) : ApprovalState.APPROVED
  }, [isAllowed, pendingApproval, spender])
}
export function useUniNFTApprovalStateForTokenId(
  spender: string | undefined,
  tokenId: BigNumber,
  useIsPendingApproval: (spender?: string) => boolean
): ApprovalState {
  const { account } = useActiveWeb3React()
  const isAllowed = useUniNFTTokenIdAllowance(tokenId, spender)
  const pendingApproval = useIsPendingApproval(spender)

  return useMemo(() => {
    if (!spender) return ApprovalState.UNKNOWN
    // we might not have enough data to know whether or not we need to approve
    if (isAllowed == undefined) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return !isAllowed ? (pendingApproval ? ApprovalState.PENDING : ApprovalState.NOT_APPROVED) : ApprovalState.APPROVED
  }, [isAllowed, pendingApproval, spender])
}

export function useUniNFTApproval(
  spender: string | undefined,
  useIsPendingApproval: (spender?: string) => boolean
): [
  ApprovalState,
  () => Promise<{ response: TransactionResponse; tokenAddress: string; spenderAddress: string } | undefined>
] {
  const { chainId } = useActiveWeb3React()

  // check the current approval status
  const approvalState = useUniNFTApprovalStateForSpender(spender, useIsPendingApproval)

  const uniNFT = useV3NFTPositionManagerContract()

  const approve = useCallback(async () => {
    function logFailure(error: Error | string): undefined {
      console.warn(`Uni V3 approval failed:`, error)
      return
    }

    // Bail early if there is an issue.
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      return logFailure('approve was called unnecessarily')
    } else if (!chainId) {
      return logFailure('no chainId')
    } else if (!uniNFT) {
      return logFailure('UniNFT is null')
    } else if (!spender) {
      return logFailure('no spender')
    }

    let useExact = false
    const estimatedGas = await uniNFT.estimateGas.setApprovalForAll(spender, true).catch(() => {
      // general fallback for tokens which restrict approval amounts
      useExact = true
      return uniNFT.estimateGas.setApprovalForAll(spender, true)
    })

    return uniNFT
      .setApprovalForAll(spender, true, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response) => ({
        response,
        tokenAddress: uniNFT.address,
        spenderAddress: spender,
      }))
      .catch((error: Error) => {
        logFailure(error)
        throw error
      })
  }, [approvalState, uniNFT, spender, chainId])

  return [approvalState, approve]
}

export function useUniNFTApprovalForTokenId(
  spender: string | undefined,
  tokenId: BigNumber,
  useIsPendingApproval: (spender?: string) => boolean
): [
  ApprovalState,
  () => Promise<{ response: TransactionResponse; tokenAddress: string; spenderAddress: string } | undefined>
] {
  const { chainId } = useActiveWeb3React()

  // check the current approval status
  const approvalState = useUniNFTApprovalStateForTokenId(spender, tokenId, useIsPendingApproval)

  const uniNFT = useV3NFTPositionManagerContract()

  const approve = useCallback(async () => {
    function logFailure(error: Error | string): undefined {
      console.warn(`Uni V3 approval failed:`, error)
      return
    }

    // Bail early if there is an issue.
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      return logFailure('approve was called unnecessarily')
    } else if (!chainId) {
      return logFailure('no chainId')
    } else if (!uniNFT) {
      return logFailure('UniNFT is null')
    } else if (!spender) {
      return logFailure('no spender')
    }

    let useExact = false
    const estimatedGas = await uniNFT.estimateGas.approve(spender, tokenId).catch(() => {
      // general fallback for tokens which restrict approval amounts
      useExact = true
      return uniNFT.estimateGas.approve(spender, tokenId)
    })

    return uniNFT
      .approve(spender, tokenId, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response) => ({
        response,
        tokenAddress: uniNFT.address,
        spenderAddress: spender,
      }))
      .catch((error: Error) => {
        logFailure(error)
        throw error
      })
  }, [approvalState, uniNFT, spender, chainId])

  return [approvalState, approve]
}
