import { UUSD } from 'constants/tokens'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import { useMemo } from 'react'

import useActiveWeb3React from './useActiveWeb3React'

const useChadBalance = (account: string | undefined) => {
  const { chainId } = useActiveWeb3React()
  const token = UUSD[chainId ? chainId : 10]
  const address = useMemo(() => account, [account])
  return useTokenBalance(address, token)
}

export default useChadBalance
