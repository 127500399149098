import { BigNumber } from '@ethersproject/bignumber'
import { CallStateResult, useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useMemo } from 'react'
import { PositionDetails } from 'types/position'

import { useChadFinanceVaultContract, useV3NFTPositionManagerContract } from './useContract'

export interface ChadFinancePosition {
  position: PositionDetails
  debt: BigNumber
  maxDebt: BigNumber
}

interface UseChadFinancePositionsResults {
  loading: boolean
  positions: ChadFinancePosition[] | undefined
}

function useChadFinancePositionsFromTokenIds(tokenIds: BigNumber[] | undefined): UseChadFinancePositionsResults {
  const vault = useChadFinanceVaultContract()
  const positionManager = useV3NFTPositionManagerContract()
  const inputs = useMemo(() => (tokenIds ? tokenIds.map((tokenId) => [BigNumber.from(tokenId)]) : []), [tokenIds])
  const debtResults = useSingleContractMultipleData(vault, 'getDebtInfo', inputs)
  const posResults = useSingleContractMultipleData(positionManager, 'positions', inputs)
  const loading = useMemo(() => posResults.some(({ loading }) => loading), [posResults])
  const error = useMemo(() => posResults.some(({ error }) => error), [posResults])

  const debtLoading = useMemo(() => debtResults.some(({ loading }) => loading), [debtResults])
  const debtError = useMemo(() => debtResults.some(({ error }) => error), [debtResults])
  const positions: ChadFinancePosition[] | undefined = useMemo(() => {
    if (!loading && !error && !debtLoading && !debtError && debtResults && tokenIds) {
      const results: Map<BigNumber, ChadFinancePosition> = new Map<BigNumber, ChadFinancePosition>()
      posResults.map((call, i) => {
        const tokenId = tokenIds[i]
        const result = call.result as CallStateResult
        if (!result.liquidity?.isZero()) {
          results.set(tokenId, {
            position: {
              tokenId,
              fee: result.fee,
              feeGrowthInside0LastX128: result.feeGrowthInside0LastX128,
              feeGrowthInside1LastX128: result.feeGrowthInside1LastX128,
              liquidity: result.liquidity,
              nonce: result.nonce,
              operator: result.operator,
              tickLower: result.tickLower,
              tickUpper: result.tickUpper,
              token0: result.token0,
              token1: result.token1,
              tokensOwed0: result.tokensOwed0,
              tokensOwed1: result.tokensOwed1,
            },
            debt: BigNumber.from(0),
            maxDebt: BigNumber.from(0),
          })
        }
        return result
      })
      debtResults.map((call, i) => {
        const tokenId = tokenIds[i]
        const result = call.result as CallStateResult
        const chadFinancePos = results.get(tokenId)
        if (chadFinancePos) {
          chadFinancePos.debt = result.totalDebt
          chadFinancePos.maxDebt = result.max
          results.set(tokenId, chadFinancePos)
        }
        return result
      })

      return [...results].map(([name, value]) => value)
    }
    return undefined
  }, [loading, error, posResults, debtLoading, debtError, debtResults, tokenIds])

  return {
    loading: loading || debtLoading,
    positions: positions?.map((position, i) => ({ ...position, tokenId: inputs[i][0] })),
  }
}
function useSwapPositionfromTokenIds(tokenIds: BigNumber[] | undefined): UseChadFinancePositionsResults {
  const positionManager = useV3NFTPositionManagerContract()
  const inputs = useMemo(() => (tokenIds ? tokenIds.map((tokenId) => [BigNumber.from(tokenId)]) : []), [tokenIds])
  const posResults = useSingleContractMultipleData(positionManager, 'positions', inputs)
  const loading = useMemo(() => posResults.some(({ loading }) => loading), [posResults])
  const error = useMemo(() => posResults.some(({ error }) => error), [posResults])
  const positions: ChadFinancePosition[] | undefined = useMemo(() => {
    if (!loading && !error && tokenIds) {
      const results: Map<BigNumber, ChadFinancePosition> = new Map<BigNumber, ChadFinancePosition>()
      posResults.map((call, i) => {
        const tokenId = tokenIds[i]
        const result = call.result as CallStateResult
        if (!result.liquidity?.isZero()) {
          results.set(tokenId, {
            position: {
              tokenId,
              fee: result.fee,
              feeGrowthInside0LastX128: result.feeGrowthInside0LastX128,
              feeGrowthInside1LastX128: result.feeGrowthInside1LastX128,
              liquidity: result.liquidity,
              nonce: result.nonce,
              operator: result.operator,
              tickLower: result.tickLower,
              tickUpper: result.tickUpper,
              token0: result.token0,
              token1: result.token1,
              tokensOwed0: result.tokensOwed0,
              tokensOwed1: result.tokensOwed1,
            },
            debt: BigNumber.from(0),
            maxDebt: BigNumber.from(0),
          })
        }
        return result
      })

      return [...results].map(([name, value]) => value)
    }
    return undefined
  }, [loading, error, posResults, tokenIds])

  return {
    loading,
    positions: positions?.map((position, i) => ({ ...position, tokenId: inputs[i][0] })),
  }
}
export function useSwapPositionfromTokenId(tokenId: BigNumber | undefined): UseChadFinancePositionResults {
  const position = useSwapPositionfromTokenIds(tokenId ? [tokenId] : undefined)
  return {
    loading: position.loading,
    position: position.positions?.[0],
  }
}

interface UseChadFinancePositionResults {
  loading: boolean
  position: ChadFinancePosition | undefined
}

export function useChadFinancePositionFromTokenId(tokenId: BigNumber | undefined): UseChadFinancePositionResults {
  const position = useChadFinancePositionsFromTokenIds(tokenId ? [tokenId] : undefined)
  return {
    loading: position.loading,
    position: position.positions?.[0],
  }
}

export function useChadFinancePositions(account: string | null | undefined): UseChadFinancePositionsResults {
  const vault = useChadFinanceVaultContract()

  const { loading: balanceLoading, result: balanceResult } = useSingleCallResult(vault, 'balanceOf', [
    account ?? undefined,
  ])

  const accountBalance: number | undefined = balanceResult?.[0]?.toNumber()
  const tokenIdsArgs = useMemo(() => {
    if (accountBalance && account) {
      const tokenRequests = []
      for (let i = 0; i < accountBalance; i++) {
        tokenRequests.push([account, i])
      }
      return tokenRequests
    }
    return []
  }, [account, accountBalance])

  const tokenIdResults = useSingleContractMultipleData(vault, 'tokenOfOwnerByIndex', tokenIdsArgs)
  const someTokenIdsLoading = useMemo(() => tokenIdResults.some(({ loading }) => loading), [tokenIdResults])

  const tokenIds = useMemo(() => {
    if (account) {
      return tokenIdResults
        .map(({ result }) => result)
        .filter((result): result is CallStateResult => !!result)
        .map((result) => BigNumber.from(result[0]))
    }
    return []
  }, [account, tokenIdResults])

  const { positions, loading: positionsLoading } = useChadFinancePositionsFromTokenIds(tokenIds)

  return {
    loading: someTokenIdsLoading || balanceLoading || positionsLoading,
    positions,
  }
}
