import { createReducer } from '@reduxjs/toolkit'
import { parsedQueryString } from 'hooks/useParsedQueryString'

import { typeInput } from './actions'
import { queryParametersRepayState } from './hooks'

export interface RepayState {
  readonly typedValue: string
}

const initialState: RepayState = queryParametersRepayState(parsedQueryString())

export default createReducer<RepayState>(initialState, (builder) =>
  builder.addCase(typeInput, (state, { payload: { typedValue } }) => {
    return {
      ...state,
      typedValue,
    }
  })
)
