import { whiteListTokenAddresses } from 'constants/whitelistToken'
import { useMemo } from 'react'
const useCheckWhitelistToken = (
  token0: string | undefined,
  token1: string | undefined,
  chainId: number | undefined
): boolean => {
  return useMemo(() => {
    if (!!token0 && !!token1) {
      const allTokens = whiteListTokenAddresses[chainId ? chainId : 1]
      if (Array.isArray(allTokens) && allTokens.length > 0) {
        return allTokens.includes(token0) && allTokens.includes(token1)
      }
    }
    return false
  }, [token0, token1, whiteListTokenAddresses])
}

export default useCheckWhitelistToken
