// eslint-disable-next-line no-restricted-imports
import { BigNumber } from '@ethersproject/bignumber'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { RepayCallbackState, useRepay as useLibSwapCallBack } from 'lib/hooks/swap/useRepay'
import { ReactNode, useMemo } from 'react'

import { useTransactionAdder } from '../state/transactions/hooks'
import { TransactionType } from '../state/transactions/types'

// returns a function that will execute a borrow, if the parameters are all valid
export function useRepayCallback(
  repayToConfirm: CurrencyAmount<Currency> | undefined,
  tokenId: BigNumber | undefined
): { state: RepayCallbackState; callback: (() => Promise<string>) | null; error: ReactNode | null } {
  const addTransaction = useTransactionAdder()

  const {
    state,
    callback: libCallback,
    error,
  } = useLibSwapCallBack({
    repayAmount: repayToConfirm ? BigNumber.from(repayToConfirm.numerator.toString()) : repayToConfirm,
    tokenId,
  })

  const callback = useMemo(() => {
    if (!libCallback || !repayToConfirm || !tokenId) {
      return null
    }

    return () =>
      libCallback().then((response) => {
        addTransaction(response, {
          type: TransactionType.REPAY,
          repayAmount: repayToConfirm.numerator.toString(),
          tokenId: Number(tokenId),
        })
        return response.hash
      })
  }, [libCallback, addTransaction, repayToConfirm, tokenId])

  return {
    state,
    callback,
    error,
  }
}
