import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import RepayHeader from 'components/Borrow/RepayHeader'
import { LoadingRows } from 'components/Loader/styled'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import RepayInputPanel from 'components/RepayInputPanel'
import { MouseoverTooltip } from 'components/Tooltip'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useChadFinancePositionFromTokenId } from 'hooks/useChadFinancePositions'
import { useChadFinanceVaultContract } from 'hooks/useContract'
import { useRepayCallback } from 'hooks/useRepayCallback'
import JSBI from 'jsbi'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { CheckCircle, HelpCircle } from 'react-feather'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import { useDerivedRepayInfo, useRepayActionHandlers } from 'state/repay/hooks'
import styled, { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { BorrowCallbackError, Wrapper } from '../../components/Borrow/styleds'
import { ButtonConfirmed, ButtonError, ButtonLight } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import Loader from '../../components/Loader'
import { AutoRow } from '../../components/Row'
import { UUSD } from '../../constants/tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useExpertModeManager } from '../../state/user/hooks'
import AppBody from '../AppBody'

const AlertWrapper = styled.div`
  max-width: 460px;
  width: 100%;
`
const HoverText = styled(ThemedText.Main)`
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  :hover {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
  }
`

export default function Repay({
  match: {
    params: { tokenId: tokenIdFromUrl },
  },
  history,
}: RouteComponentProps<{ tokenId?: string }>) {
  const { account, chainId } = useActiveWeb3React()
  const parsedTokenId = tokenIdFromUrl ? BigNumber.from(tokenIdFromUrl) : undefined

  const position = useChadFinancePositionFromTokenId(parsedTokenId)

  const balance = useCurrencyBalance(account ?? undefined, UUSD[chainId ? chainId : 1])

  const maxAllowed = useMemo(() => {
    if (position.loading) {
      return CurrencyAmount.fromRawAmount(UUSD[chainId ? chainId : 1], '0')
    }

    if (!position.loading && position.position && balance) {
      return balance.greaterThan(JSBI.BigInt(position.position.debt))
        ? CurrencyAmount.fromRawAmount(
            UUSD[chainId ? chainId : 1],
            position.position?.debt.add(position.position.debt.div(100)).toString()
          )
        : balance
    }

    return CurrencyAmount.fromRawAmount(UUSD[chainId ? chainId : 1], '0')
  }, [position])
  const currency = UUSD[chainId ? chainId : 1]
  const { parsedAmount, inputError: swapInputError } = useDerivedRepayInfo()

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  const { onUserInput } = useRepayActionHandlers()
  const isValid = parsedAmount?.greaterThan(JSBI.BigInt(0))

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(value)
    },
    [onUserInput]
  )

  const [{ showConfirm, repayToConfirm, repayErrorMessage, attemptingTxn, txHash }, setRepayState] = useState<{
    showConfirm: boolean
    repayToConfirm: CurrencyAmount<Currency> | undefined
    attemptingTxn: boolean
    repayErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    repayToConfirm: CurrencyAmount.fromRawAmount(UUSD[chainId ? chainId : 1], JSBI.BigInt(0)),
    attemptingTxn: false,
    repayErrorMessage: undefined,
    txHash: undefined,
  })
  const vault = useChadFinanceVaultContract()
  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallback(parsedAmount, vault?.address)

  const handleApprove = useCallback(async () => {
    await approveCallback()
  }, [approveCallback])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalState, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(() => maxAllowed, [maxAllowed])

  const showMaxButton = Boolean(maxAllowed?.greaterThan(0) && !parsedAmount?.lessThan(maxAllowed))

  const { state: repayState, callback: repayCallback } = useRepayCallback(parsedAmount, parsedTokenId)

  const handleBorrow = useCallback(async () => {
    if (!repayCallback || !parsedAmount?.greaterThan(JSBI.BigInt(0))) {
      return
    }

    setRepayState({
      attemptingTxn: true,
      repayToConfirm: parsedAmount,
      showConfirm,
      repayErrorMessage: undefined,
      txHash: undefined,
    })

    repayCallback()
      .then((hash) => {
        setRepayState({
          attemptingTxn: false,
          repayToConfirm: parsedAmount,
          showConfirm,
          repayErrorMessage: undefined,
          txHash: hash,
        })
      })
      .catch((error) => {
        setRepayState({
          attemptingTxn: false,
          repayToConfirm: parsedAmount,
          showConfirm,
          repayErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [repayCallback, parsedAmount, showConfirm, account, parsedTokenId])

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    approvalState === ApprovalState.NOT_APPROVED ||
    approvalState === ApprovalState.PENDING ||
    (approvalSubmitted && approvalState === ApprovalState.APPROVED)
  const handleMaxInput = useCallback(() => {
    // let value=(Number(maxAllowed.toExact())+0.01*Number(maxAllowed.toExact())).to
    maxInputAmount && onUserInput(maxAllowed.toExact())
  }, [maxInputAmount, onUserInput])
  return (
    <>
      {parsedTokenId && (
        <>
          <Link
            style={{
              justifySelf: 'start',
              textDecoration: 'none',
              marginBottom: '0.5rem',
              width: '100%',
              maxWidth: '480px',
            }}
            to={`/borrow/position/${tokenIdFromUrl}`}
          >
            <HoverText>
              <Trans>← Back to Pool</Trans>
            </HoverText>
          </Link>
          {/* <ContractWarningBanner maxWidth={'480px'} /> */}
          <AppBody>
            <RepayHeader />
            <Wrapper id="repay-page">
              {Boolean(position.loading) ? (
                <LoadingRows style={{ gap: '8px' }}>
                  <div />
                  <div />
                  <div />
                </LoadingRows>
              ) : (
                <AutoColumn gap={'sm'}>
                  <div style={{ display: 'relative' }}>
                    <RepayInputPanel
                      tokenId={parsedTokenId}
                      label={<Trans>Repay</Trans>}
                      value={parsedAmount?.toExact() || ''}
                      showMaxButton={showMaxButton}
                      currency={currency}
                      onUserInput={handleTypeInput}
                      onMax={handleMaxInput}
                      showCommonBases={true}
                      id="repay-input"
                      loading={position.loading}
                    />
                  </div>

                  <div>
                    {!account ? (
                      <ButtonLight onClick={toggleWalletModal}>
                        <Trans>Connect Wallet</Trans>
                      </ButtonLight>
                    ) : showApproveFlow ? (
                      <AutoRow style={{ flexWrap: 'nowrap', width: '100%' }}>
                        <AutoColumn style={{ width: '100%' }} gap="12px">
                          <ButtonConfirmed
                            onClick={handleApprove}
                            disabled={approvalState !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                            width="100%"
                            altDisabledStyle={approvalState === ApprovalState.PENDING} // show solid button while waiting
                            confirmed={approvalState === ApprovalState.APPROVED}
                          >
                            <AutoRow justify="space-between" style={{ flexWrap: 'nowrap' }}>
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                {/* we need to shorten this string on mobile */}
                                {approvalState === ApprovalState.APPROVED ? (
                                  <Trans>You can now use your CUSD to repay your vault position</Trans>
                                ) : (
                                  <Trans>Allow the ChadFinance Protocol to use your CUSD</Trans>
                                )}
                              </span>

                              {approvalState === ApprovalState.PENDING ? (
                                <Loader stroke="white" />
                              ) : approvalSubmitted && approvalState === ApprovalState.APPROVED ? (
                                <CheckCircle size="20" color={theme.green1} />
                              ) : (
                                <MouseoverTooltip
                                  text={
                                    <Trans>
                                      You must give the Chad Finance smart contracts permission to use your CUSD to
                                      repay your debt.
                                    </Trans>
                                  }
                                >
                                  <HelpCircle size="20" color={'white'} style={{ marginLeft: '8px' }} />
                                </MouseoverTooltip>
                              )}
                            </AutoRow>
                          </ButtonConfirmed>
                          <ButtonError
                            onClick={handleBorrow}
                            width="100%"
                            id="borrow-button"
                            disabled={!isValid || position.loading || approvalState !== ApprovalState.APPROVED}
                            error={isValid}
                          >
                            <Text fontSize={16} fontWeight={500}>
                              <Trans>Repay</Trans>
                            </Text>
                          </ButtonError>
                        </AutoColumn>
                      </AutoRow>
                    ) : (
                      <ButtonError
                        onClick={handleBorrow}
                        id="repay-button"
                        disabled={!isValid || position.loading}
                        error={isValid}
                      >
                        <Text fontSize={20} fontWeight={500}>
                          {swapInputError ? (
                            swapInputError
                          ) : position.loading ? (
                            <Trans>Loading position</Trans>
                          ) : (
                            <Trans>Repay</Trans>
                          )}
                        </Text>
                      </ButtonError>
                    )}
                    {isExpertMode && repayErrorMessage ? <BorrowCallbackError error={repayErrorMessage} /> : null}
                  </div>
                </AutoColumn>
              )}
            </Wrapper>
          </AppBody>
        </>
      )}
      <AlertWrapper>
        <NetworkAlert />
      </AlertWrapper>
    </>
  )
}
