/**
 * List of all the networks supported by the ChadFinance Interface
 */
export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
  OPTIMISM = 10,
  BASE = 8453,
  SCROLL = 534352,
  SCROLL_TESTNET = 534351,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.ROPSTEN]: 'ropsten',
  [SupportedChainId.RINKEBY]: 'rinkeby',
  [SupportedChainId.GOERLI]: 'goerli',
  [SupportedChainId.KOVAN]: 'kovan',
  [SupportedChainId.BASE]: 'base',
  [SupportedChainId.OPTIMISM]: 'optimism',
  [SupportedChainId.SCROLL]: 'scroll',
  [SupportedChainId.SCROLL_TESTNET]: 'scroll sepolia',
}
export const DEXSupportedChainids = [SupportedChainId.SCROLL, SupportedChainId.SCROLL_TESTNET]

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.BASE,
  SupportedChainId.OPTIMISM,
]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [
  SupportedChainId.BASE,
  SupportedChainId.OPTIMISM,
  SupportedChainId.SCROLL_TESTNET,
  SupportedChainId.SCROLL,
] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]
