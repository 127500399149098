import { Contract } from '@ethersproject/contracts'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { UNI_STABLE_VAULT } from 'constants/addresses'
import { UUSD } from 'constants/tokens'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useMemo } from 'react'

import useActiveWeb3React from './useActiveWeb3React'
import { useConjurerContract } from './useContract'
const useConjurerVaultInfo = () => {
  const { chainId } = useActiveWeb3React()
  const contract: Contract | null = useConjurerContract()
  const inputs = useMemo(() => {
    if (!chainId) return
    return UNI_STABLE_VAULT[chainId]
  }, [chainId, UNI_STABLE_VAULT])
  const vaultInfo = useSingleCallResult(contract, 'vaultInfo', [inputs])
  return useMemo(() => {
    return {
      loading: false,
      result: {
        totalMinted: vaultInfo?.result
          ? CurrencyAmount?.fromRawAmount(UUSD[chainId ? chainId : 1], vaultInfo.result[0]?.totalConjured ?? 0)
          : undefined,
        conjurerLimit: vaultInfo?.result
          ? CurrencyAmount?.fromRawAmount(UUSD[chainId ? chainId : 1], vaultInfo.result[0]?.conjureLimit ?? 0)
          : undefined,
      },
    }
  }, [vaultInfo])
}

export default useConjurerVaultInfo
