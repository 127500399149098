import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { RowBetween, RowFixed } from '../Row'

const StyledBorrowHeader = styled.div`
  padding: 1rem 1.25rem 0.5rem 1.25rem;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`

export default function BorrowHeader() {
  return (
    <StyledBorrowHeader>
      <RowBetween>
        <RowFixed>
          <ThemedText.Black fontWeight={500} fontSize={16} style={{ marginRight: '8px' }}>
            <Trans>Borrow</Trans>
          </ThemedText.Black>
        </RowFixed>
      </RowBetween>
    </StyledBorrowHeader>
  )
}
