import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { LoadingOpacityContainer, loadingOpacityMixin } from 'components/Loader/styled'
import { UUSD } from 'constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useChadFinancePositionFromTokenId } from 'hooks/useChadFinancePositions'
import { darken } from 'polished'
import { ReactNode, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import useTheme from '../../hooks/useTheme'
import { ThemedText } from '../../theme'
import { ButtonGray } from '../Button'
import CurrencyLogo from '../CurrencyLogo'
import { Input as NumericalInput } from '../NumericalInput'
import { RowBetween, RowFixed } from '../Row'

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  background-color: ${({ theme, hideInput }) => (hideInput ? 'transparent' : theme.bg2)};
  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  transition: height 1s ease;
  will-change: height;
`

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.bg2};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  border: 1px solid ${({ theme }) => theme.bg0};
  background-color: ${({ theme }) => theme.bg1};
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  :focus,
  :hover {
    border: 1px solid ${({ theme, hideInput }) => (hideInput ? ' transparent' : theme.bg3)};
  }
`

const CurrencySelect = styled(ButtonGray)<{ visible: boolean; selected: boolean; hideInput?: boolean }>`
  align-items: center;
  background-color: ${({ selected, theme }) => (selected ? theme.bg2 : theme.primary1)};
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  cursor: pointer;
  border-radius: 16px;
  outline: none;
  user-select: none;
  border: none;
  font-size: 24px;
  font-weight: 500;
  height: ${({ hideInput }) => (hideInput ? '2.8rem' : '2.4rem')};
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  padding: 0 8px;
  justify-content: space-between;
  margin-left: ${({ hideInput }) => (hideInput ? '0' : '12px')};
  :focus,
  :hover {
    background-color: ${({ selected, theme }) => (selected ? theme.bg3 : darken(0.05, theme.primary1))};
  }
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: space-between;
  padding: ${({ selected }) => (selected ? ' 1rem 1rem 0.75rem 1rem' : '1rem 1rem 1rem 1rem')};
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0 1rem 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const FiatRow = styled(LabelRow)`
  justify-content: flex-end;
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.35rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.25rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size:  ${({ active }) => (active ? '18px' : '18px')};
`

const StyledBalanceMax = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  background-color: ${({ theme }) => theme.primary5};
  border: none;
  border-radius: 12px;
  color: ${({ theme }) => theme.primary1};
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  margin-left: 0.25rem;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  padding: 4px 6px;
  pointer-events: ${({ disabled }) => (!disabled ? 'initial' : 'none')};

  :hover {
    opacity: ${({ disabled }) => (!disabled ? 0.8 : 0.4)};
  }

  :focus {
    outline: none;
  }
`

const StyledNumericalInput = styled(NumericalInput)<{ $loading: boolean }>`
  ${loadingOpacityMixin};
  text-align: left;
`

interface BorrowInputPanelProps {
  tokenId: BigNumber
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: ReactNode
  currency?: Currency | null
  hideInput?: boolean
  id: string
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  loading?: boolean
}

export default function BorrowInputPanel({
  tokenId,
  value,
  onUserInput,
  onMax,
  showMaxButton,
  currency,
  id,
  showCommonBases,
  showCurrencyAmount,
  renderBalance,
  hideInput = false,
  loading = false,
  ...rest
}: BorrowInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { account, chainId } = useActiveWeb3React()
  const position = useChadFinancePositionFromTokenId(tokenId)

  const maxBorrowBalance = useMemo(() => {
    if (position.loading) {
      return CurrencyAmount.fromRawAmount(UUSD[chainId ? chainId : 1], '0')
    }

    if (!position.loading && position.position && position.position.debt.lte(position.position.maxDebt)) {
      const borrowValue = position.position.maxDebt
        .sub(position.position.debt)
        .mul(BigNumber.from(98))
        .div(BigNumber.from(100))

      return CurrencyAmount.fromRawAmount(UUSD[chainId ? chainId : 1], borrowValue.toString())
    }

    return CurrencyAmount.fromRawAmount(UUSD[chainId ? chainId : 1], '0')
  }, [position])

  const theme = useTheme()

  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      <Container hideInput={hideInput}>
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={true}>
          {!hideInput && (
            <StyledNumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={onUserInput}
              $loading={loading}
            />
          )}

          <CurrencySelect
            visible={currency !== undefined}
            selected={!!maxBorrowBalance.currency}
            hideInput={hideInput}
            className="open-currency-select-button"
          >
            <Aligner>
              <RowFixed>
                {currency ? <CurrencyLogo style={{ marginRight: '0.5rem' }} currency={currency} size={'24px'} /> : null}
                {
                  <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                    {currency && currency.symbol && currency.symbol.length > 20
                      ? currency.symbol.slice(0, 4) +
                        '...' +
                        currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                      : currency?.symbol}
                  </StyledTokenName>
                }
              </RowFixed>
            </Aligner>
          </CurrencySelect>
        </InputRow>
        {maxBorrowBalance && (
          <FiatRow>
            <RowBetween>
              <LoadingOpacityContainer $loading={loading}></LoadingOpacityContainer>
              {account ? (
                <RowFixed style={{ height: '17px' }}>
                  <ThemedText.Body
                    onClick={onMax}
                    color={theme.text3}
                    fontWeight={500}
                    fontSize={14}
                    style={{ display: 'inline', cursor: 'pointer' }}
                  >
                    {currency && maxBorrowBalance ? (
                      renderBalance ? (
                        renderBalance(maxBorrowBalance)
                      ) : (
                        <Trans>Max Borrow: {formatCurrencyAmount(maxBorrowBalance, 6)}</Trans>
                      )
                    ) : null}
                  </ThemedText.Body>
                  {showMaxButton && maxBorrowBalance ? (
                    <StyledBalanceMax onClick={onMax}>
                      <Trans>MAX</Trans>
                    </StyledBalanceMax>
                  ) : null}
                </RowFixed>
              ) : (
                <span />
              )}
            </RowBetween>
          </FiatRow>
        )}
      </Container>
    </InputPanel>
  )
}
