import { Trans } from '@lingui/macro'
import Column from 'components/Column'
import Row from 'components/Row'
import { whitelistTokens } from 'constants/whitelistToken'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import React from 'react'
import { ThemedText } from 'theme'

import TokenBase from './TokenBAse'
const WhitelistTokenModal = () => {
  const { chainId } = useActiveWeb3React()
  return (
    <Column style={{ padding: '16px', gap: '8px' }}>
      <Row style={{ paddingBottom: '6px' }}>
        <ThemedText.MediumHeader>Whitelisted Tokens</ThemedText.MediumHeader>
      </Row>

      <Column style={{ gap: '8px' }}>
        <Row style={{ padding: '0.75', gap: '8px', justifyContent: 'flex-start', display: 'flex', flexWrap: 'wrap' }}>
          {Array.isArray(whitelistTokens[chainId ? chainId : 1]) &&
          Boolean(whitelistTokens[chainId ? chainId : 1].length) ? (
            whitelistTokens[chainId ? chainId : 1].map((token) => {
              if (token) {
                return <TokenBase value={token} />
              }
              return null
            })
          ) : (
            <Trans>No Whitelisted Token </Trans>
          )}
        </Row>
      </Column>
    </Column>
  )
}

export default WhitelistTokenModal
