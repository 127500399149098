import { DEXSupportedChainids } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

// Redirects to swap but only replace the pathname
export function RedirectPathToBorrowOnly({ location }: RouteComponentProps) {
  return <Redirect to={{ ...location, pathname: '/borrow' }} />
}
export function RedirectPathToSwapOnly({ location }: RouteComponentProps) {
  const { chainId } = useActiveWeb3React()
  const getPathname = useCallback(() => {
    if (chainId && DEXSupportedChainids.includes(chainId)) {
      return '/swap'
    }
    return '/borrow'
  }, [chainId])
  return <Redirect to={'/swap'} />
}
