import { Currency } from '@uniswap/sdk-core'
import { BaseButton } from 'components/Button'
import Row from 'components/Row'
import styled from 'styled-components/macro'

import TokenImg from './TokenImg'

const TokenButton = styled(BaseButton)`
  padding: 0.25em 0.75em 0.25em 0.25em;
  width: max-content;
  background: ${({ theme }) => theme.bg2};
  border: 1px solid ${({ theme }) => theme.bg3};
`

interface TokenBaseProps {
  value: Currency
}

export default function TokenBase({ value }: TokenBaseProps) {
  return (
    <TokenButton>
      <Row style={{ gap: '4px' }}>
        <TokenImg token={value} size={1.5} />
        {value.symbol}
      </Row>
    </TokenButton>
  )
}
