// eslint-disable-next-line no-restricted-imports
import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useChadFinanceVaultContract } from 'hooks/useContract'
import { ReactNode, useMemo } from 'react'
import { calculateGasMargin } from 'utils/calculateGasMargin'

export enum BorrowCallbackState {
  INVALID,
  LOADING,
  VALID,
}

interface UseBorrowCallbackReturns {
  state: BorrowCallbackState
  callback?: () => Promise<TransactionResponse>
  error?: ReactNode
}

interface UseBorrowCallbackArgs {
  borrowAmount: BigNumber | undefined
  tokenId: BigNumber | undefined
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useBorrow({ borrowAmount, tokenId }: UseBorrowCallbackArgs): UseBorrowCallbackReturns {
  const { account, chainId, library } = useActiveWeb3React()

  const vault = useChadFinanceVaultContract()

  return useMemo(() => {
    if (!borrowAmount || !library || !account || !chainId || !vault || !tokenId) {
      return { state: BorrowCallbackState.INVALID, error: <Trans>Missing dependencies</Trans> }
    }

    return {
      state: BorrowCallbackState.VALID,
      callback: async () => {
        const estimatedGas = await vault.estimateGas.borrow(tokenId, borrowAmount).catch(() => {
          // general fallback for tokens which restrict approval amounts
          throw Error('Invalid borrow amount')
        })

        return vault
          .borrow(tokenId, borrowAmount, {
            gasLimit: calculateGasMargin(estimatedGas),
          })
          .catch((error: Error) => {
            throw error
          })
      },
    }
  }, [library, account, chainId, borrowAmount, tokenId, vault])
}
