import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ParsedQs } from 'qs'
import { ReactNode, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { TOKEN_SHORTHANDS, UUSD } from '../../constants/tokens'
import { useCurrency } from '../../hooks/Tokens'
import { isAddress } from '../../utils'
import { AppState } from '../index'
import { typeInput } from './actions'
import { RepayState } from './reducer'

export function useRepayState(): AppState['repay'] {
  return useAppSelector((state) => state.repay)
}

export function useRepayActionHandlers(): {
  onUserInput: (typedValue: string) => void
} {
  const dispatch = useAppDispatch()

  const onUserInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ typedValue }))
    },
    [dispatch]
  )

  return {
    onUserInput,
  }
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedRepayInfo(): {
  parsedAmount: CurrencyAmount<Currency> | undefined
  inputError?: ReactNode
} {
  const { account, chainId } = useActiveWeb3React()

  const { typedValue } = useRepayState()

  const currency = useCurrency(UUSD[chainId ? chainId : 1].address)

  const parsedAmount = useMemo(() => tryParseCurrencyAmount(typedValue, currency ?? undefined), [typedValue])

  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined

    if (!account) {
      inputError = <Trans>Connect Wallet</Trans>
    }

    if (!parsedAmount) {
      inputError = inputError ?? <Trans>Enter an amount</Trans>
    }

    return inputError
  }, [account, parsedAmount])

  return useMemo(
    () => ({
      parsedAmount,
      inputError,
    }),
    [inputError, parsedAmount]
  )
}

function parseCurrencyFromURLParameter(urlParam: ParsedQs[string]): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam)
    if (valid) return valid
    const upper = urlParam.toUpperCase()
    if (upper === 'ETH') return 'ETH'
    if (upper in TOKEN_SHORTHANDS) return upper
  }
  return ''
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null
  const address = isAddress(recipient)
  if (address) return address
  if (ENS_NAME_REGEX.test(recipient)) return recipient
  if (ADDRESS_REGEX.test(recipient)) return recipient
  return null
}

export function queryParametersRepayState(parsedQs: ParsedQs): RepayState {
  const typedValue = parseTokenAmountURLParameter(parsedQs.exactAmount)

  return {
    typedValue,
  }
}
