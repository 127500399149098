import { createReducer } from '@reduxjs/toolkit'
import { parsedQueryString } from 'hooks/useParsedQueryString'

import { typeInput } from './actions'
import { queryParametersToBorrowState } from './hooks'

export interface BorrowState {
  readonly typedValue: string
}

const initialState: BorrowState = queryParametersToBorrowState(parsedQueryString())

export default createReducer<BorrowState>(initialState, (builder) =>
  builder.addCase(typeInput, (state, { payload: { typedValue } }) => {
    return {
      ...state,
      typedValue,
    }
  })
)
