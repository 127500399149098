// eslint-disable-next-line no-restricted-imports
import { BigNumber } from '@ethersproject/bignumber'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { BorrowCallbackState, useBorrow as useLibSwapCallBack } from 'lib/hooks/swap/useBorrow'
import { ReactNode, useMemo } from 'react'

import { useTransactionAdder } from '../state/transactions/hooks'
import { TransactionType } from '../state/transactions/types'

// returns a function that will execute a borrow, if the parameters are all valid
export function useBorrowCallback(
  borrowToConfirm: CurrencyAmount<Currency> | undefined,
  tokenId: BigNumber | undefined
): { state: BorrowCallbackState; callback: (() => Promise<string>) | null; error: ReactNode | null } {
  const addTransaction = useTransactionAdder()

  const {
    state,
    callback: libCallback,
    error,
  } = useLibSwapCallBack({
    borrowAmount: borrowToConfirm ? BigNumber.from(borrowToConfirm.numerator.toString()) : borrowToConfirm,
    tokenId,
  })

  const callback = useMemo(() => {
    if (!libCallback || !borrowToConfirm || !tokenId) {
      return null
    }

    return () =>
      libCallback().then((response) => {
        addTransaction(response, {
          type: TransactionType.BORROW,
          borrowAmount: borrowToConfirm.numerator.toString(),
          tokenId: Number(tokenId),
        })
        return response.hash
      })
  }, [libCallback, addTransaction, borrowToConfirm, tokenId])

  return {
    state,
    callback,
    error,
  }
}
