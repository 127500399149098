import { getParsedChainId } from 'components/Header/NetworkSelector'
import { DEXSupportedChainids } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useParsedQueryString from 'hooks/useParsedQueryString'
import usePrevious from 'hooks/usePrevious'
import { useCallback } from 'react'
import { Redirect, Route } from 'react-router-dom'
interface SwapRouteProp {
  component: any
  path: string
}
const SwapRoute = ({ component, path }: SwapRouteProp) => {
  const { chainId } = useActiveWeb3React()
  const previousChainId = usePrevious(chainId)
  const parsedQs = useParsedQueryString()
  const { urlChain, urlChainId } = getParsedChainId(parsedQs)
  const getRedirectRoute = useCallback(() => {
    if (!chainId && !previousChainId) {
      return null
    } else if (
      (chainId && DEXSupportedChainids.includes(chainId)) ||
      (previousChainId && DEXSupportedChainids.includes(previousChainId)) ||
      (urlChainId && DEXSupportedChainids.includes(urlChainId))
    ) {
      return <Redirect to={'/swap'} />
    } else {
      return <Redirect to={'/borrow'} />
    }
  }, [chainId, previousChainId])

  // },[chainId,previousChainId])
  return chainId && DEXSupportedChainids.includes(chainId) ? (
    <Route path={path} component={component} />
  ) : (
    getRedirectRoute()
  )
  // return getRoute()
}

export default SwapRoute
