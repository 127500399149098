import Loader from 'components/Loader'
import SwapRoute from 'components/SwapRoutes'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import TopLevelModals from 'components/TopLevelModals'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'

import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import AllPools from './AllPools'
import { PoolPositionPage } from './AllPools/PositionPage'
import Borrow from './Borrow'
import { RedirectPathToSwapOnly } from './Borrow/redirects'
import Pool from './Pool'
import { PositionPage } from './Pool/PositionPage'
import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import Repay from './Repay'
import Swap from './Swap'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 16px 0px 16px;
  align-items: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4rem 8px 16px 8px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  const { chainId } = useActiveWeb3React()

  return (
    <ErrorBoundary>
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <Web3ReactManager>
        <AppWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            <Polling />
            {/* <TopLevelModals /> */}
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route exact strict path="/borrow" component={Pool} />

                {/* TBD */}
                <Route exact strict path="/borrow/position/:tokenId" component={PositionPage} />

                <Route exact strict path="/borrow/:tokenId" component={Borrow} />
                <SwapRoute path="/pool/:tokenId" component={PoolPositionPage} />
                <SwapRoute path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?" component={AddLiquidity} />
                <SwapRoute path="/remove/:tokenId?" component={RemoveLiquidityV3} />
                <Route exact strict path="/repay/:tokenId" component={Repay} />
                {/* <SwapRoute path="/swap" component={Swap} /> */}
                <Route exact strict path="/swap" component={Swap} />
                {/* <SwapRoute path="/pool" component={AllPools} /> */}
                <Route exact strict path="/pool" component={AllPools} />
                <Route exact strict path="/pool/:tokenId" component={PoolPositionPage} />
                <Route path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?" component={AddLiquidity} />
                <Route path="/remove/:tokenId?" component={RemoveLiquidityV3} />
                <Route exact strict path="/swap" component={Swap} />
                {/* <Route exact strict path="/swap" component={<SwapRoute Child={Swap} />} /> */}

                {/* {chainId && ! DEXSupportedChainids.includes(chainId) && <Route component={RedirectPathToSwapOnly} />} */}
                {/* {chainId && DEXSupportedChainids.includes(chainId) && (
                  <>
                   

                    
                  </>
                )} */}
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Suspense>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </Web3ReactManager>
    </ErrorBoundary>
  )
}
