import { Currency, CurrencyAmount, TradeType, validateAndParseAddress } from '@uniswap/sdk-core'
import { ADDRESS_ZERO, encodeRouteToPath, Multicall, Payments, toHex } from '@uniswap/v3-sdk'

import { useV3RouterContract } from './useContract'

export function useSwapCalldata() {
  const SwapRouter = useV3RouterContract()
  //   const multicallContract=useinterfaceMulticall()
  //   function encodeMultiCall(calldatas: string | string[]):any{
  //     if (!Array.isArray(calldatas)) {
  //         calldatas = [calldatas]
  //       }
  //       if (multicallContract) {

  //           return calldatas.length === 1 ? calldatas[0] : multicallContract.interface.encodeFunctionData('multicall', [calldatas])
  //       }
  //   }
  function getCalldata(trades: any, options: any) {
    if (!Array.isArray(trades)) {
      trades = [trades]
    }

    const sampleTrade = trades[0]
    const tokenIn = sampleTrade.inputAmount.currency.wrapped
    const tokenOut = sampleTrade.outputAmount.currency.wrapped
    const calldatas: string[] = []

    const ZERO_IN: CurrencyAmount<Currency> = CurrencyAmount.fromRawAmount(trades[0].inputAmount.currency, 0)
    const ZERO_OUT: CurrencyAmount<Currency> = CurrencyAmount.fromRawAmount(trades[0].outputAmount.currency, 0)

    const totalAmountOut: CurrencyAmount<Currency> = trades.reduce(
      (sum: any, trade: any) => sum.add(trade.minimumAmountOut(options.slippageTolerance)),
      ZERO_OUT
    )
    const mustRefund = sampleTrade.inputAmount.currency.isNative && sampleTrade.tradeType === TradeType.EXACT_OUTPUT
    const inputIsNative = sampleTrade.inputAmount.currency.isNative
    // flags for whether funds should be send first to the router
    const outputIsNative = sampleTrade.outputAmount.currency.isNative
    const routerMustCustody = outputIsNative || !!options.fee
    const totalValue: CurrencyAmount<Currency> = inputIsNative
      ? trades.reduce((sum: any, trade: any) => sum.add(trade.maximumAmountIn(options.slippageTolerance)), ZERO_IN)
      : ZERO_IN

    const recipient: string = validateAndParseAddress(options.recipient)
    const deadline = toHex(options.deadline)
    for (const trade of trades) {
      for (const { route, inputAmount, outputAmount } of trade.swaps) {
        const amountIn: string = toHex(trade.maximumAmountIn(options.slippageTolerance, inputAmount).quotient)
        const amountOut: string = toHex(trade.minimumAmountOut(options.slippageTolerance, outputAmount).quotient)

        // flag for whether the trade is single hop or not
        const singleHop = route.pools.length === 1

        if (singleHop) {
          if (trade.tradeType === TradeType.EXACT_INPUT) {
            const exactInputSingleParams = {
              tokenIn: route.tokenPath[0].address,
              tokenOut: route.tokenPath[1].address,
              fee: route.pools[0].fee,
              recipient: routerMustCustody ? ADDRESS_ZERO : recipient,
              deadline,
              amountIn,
              amountOutMinimum: amountOut,
              sqrtPriceLimitX96: toHex(options.sqrtPriceLimitX96 ?? 0),
            }

            if (SwapRouter) {
              calldatas.push(SwapRouter.interface.encodeFunctionData('exactInputSingle', [exactInputSingleParams]))
            }
          } else {
            const exactOutputSingleParams = {
              tokenIn: route.tokenPath[0].address,
              tokenOut: route.tokenPath[1].address,
              fee: route.pools[0].fee,
              recipient: routerMustCustody ? ADDRESS_ZERO : recipient,
              deadline,
              amountOut,
              amountInMaximum: amountIn,
              sqrtPriceLimitX96: toHex(options.sqrtPriceLimitX96 ?? 0),
            }
            if (SwapRouter) {
              calldatas.push(SwapRouter.interface.encodeFunctionData('exactOutputSingle', [exactOutputSingleParams]))
            }
          }
        } else {
          const path: string = encodeRouteToPath(route, trade.tradeType === TradeType.EXACT_OUTPUT)

          if (trade.tradeType === TradeType.EXACT_INPUT) {
            const exactInputParams = {
              path,
              recipient: routerMustCustody ? ADDRESS_ZERO : recipient,
              deadline,
              amountIn,
              amountOutMinimum: amountOut,
            }
            if (SwapRouter) {
              calldatas.push(SwapRouter?.interface.encodeFunctionData('exactInput', [exactInputParams]))
            }
          } else {
            const exactOutputParams = {
              path,
              recipient: routerMustCustody ? ADDRESS_ZERO : recipient,
              deadline,
              amountOut,
              amountInMaximum: amountIn,
            }
            if (SwapRouter) {
              calldatas.push(SwapRouter.interface.encodeFunctionData('exactOutput', [exactOutputParams]))
            }
          }
        }
      }
    }

    // unwrap
    if (routerMustCustody) {
      if (!!options.fee) {
        if (outputIsNative) {
          calldatas.push(Payments.encodeUnwrapWETH9(totalAmountOut.quotient, recipient, options.fee))
        } else {
          calldatas.push(
            Payments.encodeSweepToken(
              sampleTrade.outputAmount.currency.wrapped,
              totalAmountOut.quotient,
              recipient,
              options.fee
            )
          )
        }
      } else {
        calldatas.push(Payments.encodeUnwrapWETH9(totalAmountOut.quotient, recipient))
      }
    }
    if (mustRefund) {
      calldatas.push(Payments.encodeRefundETH())
    }

    return {
      calldata: Multicall.encodeMulticall(calldatas),
      value: toHex(totalValue.quotient),
    }
  }
  return [getCalldata]
}
