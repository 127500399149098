import { Trans } from '@lingui/macro'
import { Fraction } from '@uniswap/sdk-core'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'

import { nativeOnChain, UUSD } from '../../constants/tokens'
import { useCurrency, useToken } from '../../hooks/Tokens'
import useENSName from '../../hooks/useENSName'
import {
  AddLiquidityV3PoolTransactionInfo,
  ApproveTransactionInfo,
  BorrowTransactionInfo,
  CollectFeesTransactionInfo,
  // ClaimTransactionInfo,
  DelegateTransactionInfo,
  RemoveLiquidityV3TransactionInfo,
  RepayTransactionInfo,
  TransactionInfo,
  TransactionType,
  WrapTransactionInfo,
} from '../../state/transactions/types'

function formatAmount(amountRaw: string, decimals: number, sigFigs: number): string {
  return new Fraction(amountRaw, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))).toSignificant(sigFigs)
}

function FormattedCurrencyAmount({
  rawAmount,
  symbol,
  decimals,
  sigFigs,
}: {
  rawAmount: string
  symbol: string
  decimals: number
  sigFigs: number
}) {
  return (
    <>
      {formatAmount(rawAmount, decimals, sigFigs)} {symbol}
    </>
  )
}

function FormattedCurrencyAmountManaged({
  rawAmount,
  currencyId,
  sigFigs = 6,
}: {
  rawAmount: string
  currencyId: string
  sigFigs: number
}) {
  const currency = useCurrency(currencyId)
  return currency ? (
    <FormattedCurrencyAmount
      rawAmount={rawAmount}
      decimals={currency.decimals}
      sigFigs={sigFigs}
      symbol={currency.symbol ?? '???'}
    />
  ) : null
}

function ApprovalSummary({ info }: { info: ApproveTransactionInfo }) {
  const token = useToken(info.tokenAddress)

  return <Trans>Approve {token?.symbol}</Trans>
}

function DelegateSummary({ info: { delegatee } }: { info: DelegateTransactionInfo }) {
  const { ENSName } = useENSName(delegatee)
  return <Trans>Delegate voting power to {ENSName ?? delegatee}</Trans>
}

function WrapSummary({ info: { chainId, currencyAmountRaw, unwrapped } }: { info: WrapTransactionInfo }) {
  const native = chainId ? nativeOnChain(chainId) : undefined

  if (unwrapped) {
    return (
      <Trans>
        Unwrap{' '}
        <FormattedCurrencyAmount
          rawAmount={currencyAmountRaw}
          symbol={native?.wrapped?.symbol ?? 'WETH'}
          decimals={18}
          sigFigs={6}
        />{' '}
        to {native?.symbol ?? 'ETH'}
      </Trans>
    )
  } else {
    return (
      <Trans>
        Wrap{' '}
        <FormattedCurrencyAmount
          rawAmount={currencyAmountRaw}
          symbol={native?.symbol ?? 'ETH'}
          decimals={18}
          sigFigs={6}
        />{' '}
        to {native?.wrapped?.symbol ?? 'WETH'}
      </Trans>
    )
  }
}
function AddLiquidityV3PoolSummary({
  info: { createPool, quoteCurrencyId, baseCurrencyId },
}: {
  info: AddLiquidityV3PoolTransactionInfo
}) {
  const baseCurrency = useCurrency(baseCurrencyId)
  const quoteCurrency = useCurrency(quoteCurrencyId)

  return createPool ? (
    <Trans>
      Create pool and add {baseCurrency?.symbol}/{quoteCurrency?.symbol} V3 liquidity
    </Trans>
  ) : (
    <Trans>
      Add {baseCurrency?.symbol}/{quoteCurrency?.symbol} V3 liquidity
    </Trans>
  )
}
function BorrowTransactionSummary({ info }: { info: BorrowTransactionInfo }) {
  const { chainId } = useActiveWeb3React()
  return (
    <Trans>
      Successfully Borrowed{' '}
      <FormattedCurrencyAmountManaged
        rawAmount={info.borrowAmount}
        currencyId={UUSD[chainId ? chainId : 1].address}
        sigFigs={3}
      />{' '}
    </Trans>
  )
}
function ClaimFeesTransactionSummary({ info }: { info: any }) {
  return (
    <Trans>
      Successfully Claimed{' '}
      {/* <FormattedCurrencyAmountManaged rawAmount={info.borrowAmount} currencyId={UUSD.address} sigFigs={3} />{' '} */}
    </Trans>
  )
}
function WithdrawTransactionSummary({ info }: { info: any }) {
  return (
    <Trans>
      Successfully Withdrawn{' '}
      {/* <FormattedCurrencyAmountManaged rawAmount={info.borrowAmount} currencyId={UUSD.address} sigFigs={3} />{' '} */}
    </Trans>
  )
}

function RepayTransactionSummary({ info }: { info: RepayTransactionInfo }) {
  const { chainId } = useActiveWeb3React()
  return (
    <Trans>
      Successfully Repayed{' '}
      <FormattedCurrencyAmountManaged
        rawAmount={info.repayAmount}
        currencyId={UUSD[chainId ? chainId : 1].address}
        sigFigs={3}
      />{' '}
    </Trans>
  )
}
function RemoveLiquidityV3Summary({
  info: { baseCurrencyId, quoteCurrencyId, expectedAmountBaseRaw, expectedAmountQuoteRaw },
}: {
  info: RemoveLiquidityV3TransactionInfo
}) {
  return (
    <Trans>
      Remove{' '}
      <FormattedCurrencyAmountManaged rawAmount={expectedAmountBaseRaw} currencyId={baseCurrencyId} sigFigs={3} /> and{' '}
      <FormattedCurrencyAmountManaged rawAmount={expectedAmountQuoteRaw} currencyId={quoteCurrencyId} sigFigs={3} />
    </Trans>
  )
}

function CollectFeesSummary({ info: { currencyId0, currencyId1 } }: { info: CollectFeesTransactionInfo }) {
  const currency0 = useCurrency(currencyId0)
  const currency1 = useCurrency(currencyId1)

  return (
    <Trans>
      Collect {currency0?.symbol}/{currency1?.symbol} fees
    </Trans>
  )
}

export function TransactionSummary({ info }: { info: TransactionInfo }) {
  switch (info.type) {
    case TransactionType.ADD_LIQUIDITY_V3_POOL:
      return <AddLiquidityV3PoolSummary info={info} />
    case TransactionType.APPROVAL:
      return <ApprovalSummary info={info} />

    case TransactionType.DELEGATE:
      return <DelegateSummary info={info} />
    case TransactionType.COLLECT_FEES:
      return <CollectFeesSummary info={info} />

    case TransactionType.WRAP:
      return <WrapSummary info={info} />

    case TransactionType.BORROW:
      return <BorrowTransactionSummary info={info} />
    case TransactionType.REMOVE_LIQUIDITY_V3:
      return <RemoveLiquidityV3Summary info={info} />
    case TransactionType.REPAY:
      return <RepayTransactionSummary info={info} />
    case TransactionType.CLAIM_FEES:
      return <ClaimFeesTransactionSummary info={info} />
    case TransactionType.WITHDRAW:
      return <WithdrawTransactionSummary info={info} />
  }
}
